import { useState, ChangeEvent, useCallback, useRef } from "react";
import { createRoot } from "react-dom/client";
import { processRescue, Rescue, RescueStatus, NetworkId } from "./blockchain";

import { initI18n } from "../i18n-locales/init";
import { Trans } from "react-i18next";

initI18n("en-US");

import { t } from "i18next";

const decompressBlob = async (blob: Blob) => {
  const ds = new DecompressionStream("gzip");
  const decompressedStream = blob.stream().pipeThrough(ds);
  return new Response(decompressedStream).blob();
};

type RescueStatusExtended =
  | RescueStatus
  | "ERROR"
  | "RESCUE_PUSHED"
  | "RECOVERABLE";

const App = () => {
  const [rescueStatus, setRescueStatus] = useState<RescueStatusExtended>();
  const [rescueError, setRescueError] = useState<string>();
  const [isDragOver, setIsDragOver] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const networkIdRef = useRef<NetworkId | null>(null);

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault(); // Prevent default behavior (Prevent file from being opened)
    setIsDragOver(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragOver(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      processFile(file);
      e.dataTransfer.clearData(); // Clear the drag data cache
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) processFile(file);
  };

  const onRescue = useCallback(async (rescue: Rescue) => {
    try {
      if (rescue.version !== "thunderden_rescue_V0")
        throw new Error("Invalid Rescue File Format");
      setIsLoading(true);
      const rescueStatus = await processRescue(rescue);
      networkIdRef.current = rescue.networkId;
      setRescueStatus(rescueStatus);
      return rescueStatus;
    } catch (error: unknown) {
      console.error("Error during rescue:", error);
      setRescueStatus("ERROR");
      setRescueError(String(error));
    } finally {
      setIsLoading(false);
    }
  }, []);

  const processFile = async (file: File) => {
    setIsDragOver(false);
    if (file.name.endsWith(".json.gz")) {
      const decompressedBlob = await decompressBlob(file);
      const text = await decompressedBlob.text();
      const json = JSON.parse(text);
      onRescue(json);
    } else if (file.name.endsWith(".json")) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        try {
          const json = JSON.parse(e.target?.result as string);
          onRescue(json);
        } catch (error) {
          setRescueStatus("ERROR");
          setRescueError(t("rescue.unsupportedFormat"));
        }
      };
      reader.readAsText(file);
    } else {
      setRescueStatus("ERROR");
      setRescueError(t("rescue.unsupportedFormat"));
    }
  };

  //let rescueText: undefined | string;
  //if (rescueStatus) {
  //  if (rescueStatus === "SPENT") {
  //    rescueText = setRescueStatus(
  //      ,
  //    );
  //  } else if (rescueStatus === "UNVAULT_NOT_TRIGGERED") {
  //    setRescueStatus(t("rescue.unvaultNotTriggered"));
  //  } else {
  //    const confirmation = confirm(
  //      `Confirm the transaction with a fee of ${rescueStatus.fee} and fee rate of ${rescueStatus.feeRate}. This will send the funds to the cold address.`,
  //    );
  //    if (confirmation) {
  //      // Call push function here with rescueStatus.txHex
  //      console.log("Transaction confirmed:", rescueStatus.txHex);
  //      //TODO:

  //      //TODO: how? pass this link: https://blockstream.info/tx/${txId}
  //      setRescueStatus(t("rescue.rescuePushed"));
  //    }
  //  }
  //}

  function renderRescueStatus(rescueStatus: RescueStatusExtended) {
    if (typeof rescueStatus === "object") {
      //Can be pushed:
      return t("rescue.status.RECOVERABLE", {
        fee: rescueStatus.fee,
        feeRate: rescueStatus.feeRate,
      });
    } else if (rescueStatus === "UNVAULT_NOT_TRIGGERED") {
      return t("rescue.status.UNVAULT_NOT_TRIGGERED");
    } else if (rescueStatus === "RESCUE_PUSHED") {
      return (
        <Trans
          i18nKey="rescue.status.RESCUE_PUSHED"
          components={{
            link: (
              <a href={"TODO"} target="_blank" rel="noopener noreferrer">
                {"TODO"}
              </a>
            ),
          }}
        />
      );
    } else if (rescueStatus === "SPENT") {
      return t("rescue.status.SPENT");
    } else if (rescueStatus === "ERROR") {
      return t("rescue.status.ERROR") + " " + rescueError;
    }
  }

  return (
    <>
      <div className="header">
        <h1>
          <Trans
            i18nKey="rescue.title"
            components={{ bold: <span className="logoBold" /> }}
          />
        </h1>
      </div>
      {!isLoading && !rescueStatus && (
        <div className="intro">{t("rescue.intro")}</div>
      )}
      {!isLoading ? (
        <div
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          style={{
            border: isDragOver
              ? "2px dashed var(--bold-color)"
              : "2px dashed white",
            padding: "3em",
            textAlign: "center",
          }}
        >
          {t("rescue.dragAndDrop")}
          <input
            type="file"
            onChange={handleFileChange}
            accept=".json,.gz"
            style={{
              display: "block",
              margin: "10px auto",
              cursor: "pointer",
            }}
          />
        </div>
      ) : (
        <div className="loading">
          <div className="spinner">
            <div />
            <div />
            <div />
            <div />
          </div>
          <p className="loadingText">{t("rescue.analyzing")}</p>
        </div>
      )}
      {rescueStatus && !isLoading && (
        <div className="rescueStatus">
          <h2>
            <Trans
              i18nKey="rescue.confirmationTitle"
              components={{ bold: <span className="logoBold" /> }}
            />
          </h2>
          {renderRescueStatus(rescueStatus)}
        </div>
      )}
    </>
  );
};

const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);
  root.render(<App />);
} else {
  console.error("Failed to find the root element");
}
