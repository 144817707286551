//Treat this as a golrified json with comments & multi-line using string literals
export default {
  rescue: {
    title: "Thunder Den <bold>Rescue</bold>",
    intro: `If you're on this page, you've been entrusted to help safeguard Bitcoin funds potentially facing a threat.

This task can potentially direct the funds back to the original owner's cold address.

Before you start, it's critical to attempt reaching out to the fund's owner in all possible ways to verify the threat's legitimacy. Given situations where the owner might not be reachable, proceed with caution and only if you genuinely believe a real threat exists.

The operation begins by you opening the entrusted file here, which will be processed entirely within your browser, ensuring that no data is transmitted to ThunderDen servers.

Following the file's analysis, you will be prompted to confirm any necessary actions before moving forward.`,
    analyzing: "Analyzing delegated vault rescue file...",
    dragAndDrop:
      "Drag and drop the Rescue File here or click to select a file.",
    unsupportedFormat:
      'Unsupported file type. Please open a valid Rescue File in ".json" or ".json.gz" format.',
    confirmationTitle: "<bold>Rescue</bold> File Results",
    status: {
      ERROR: "An error occurred while analyzing the Rescue File:",
      RECOVERABLE: `Press 'Confirm Recovery' to initiate the secure return of funds to the designated secure 'cold' storage.

This action will apply a transaction fee of {{fee}} and a fee rate of {{feeRate}} sats/vbyte, which will be deducted from the fund's balance, not charged to you.

Please note, initiating this recovery is irreversible. Carefully review all details before proceeding.`,
      UNVAULT_NOT_TRIGGERED: `The system did not detect any unvaulting activity.

This means that the funds are likely still secure, and there's no immediate need to cancel the unvaulting. However, it's important to re-check with the owner of the funds to confirm their status.

Additionally, stay alert over the next few hours or days, especially if you have reasons to believe there might be a threat.`,
      SPENT: `The cancellation process could not be completed. This may be because the unvaulting process has either already been cancelled or because the time-lock period has expired. In simpler terms, the window of opportunity to cancel the transaction has closed, which can happen if too much time has passed or if someone else has already secured the funds. It's important to communicate with the fund's owner for further steps. If you're new to this, think of it as a safety mechanism that only allows actions within a certain timeframe to protect the funds.`,
      RESCUE_PUSHED: `The transaction has been successfully pushed to the network.

This action has moved the funds to a secure location.

You can track the progress of this transaction and make sure it gets processed by visiting the following link:

{{link}}

It's important to ensure that the transaction is confirmed to safeguard the funds. Your proactive steps have significantly contributed to the security of the assets.`,
    },
  },
};
