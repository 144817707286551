import { EsploraExplorer, type Explorer } from "@bitcoinerlab/explorer";
import { Transaction, crypto } from "bitcoinjs-lib";

export type NetworkId = "BITCOIN" | "TESTNET" | "STORM" | "REGTEST";
type TxHex = string;
type TxId = string;
type RescueTxMap = Record<
  TxId, //The triggerTxId
  Array<{ txHex: TxHex; fee: number; feeRate: number }>
>;
export type Rescue = {
  version: "thunderden_rescue_V0";
  readme: Array<string>;
  networkId: NetworkId;
  rescueTxMap: RescueTxMap;
};

type SpendingTx = { txHex: string; fee: number; feeRate: number };
const spendingTxCache = new Map();
/**
 * Returns the tx that spent a Tx Output (or it's in the mempool about to spend it).
 * If it's in the mempool this is marked by setting blockHeight to zero.
 * This function will return early if last result was irreversible */
export async function fetchSpendingTx(
  txHex: string,
  vout: number,
  explorer: Explorer,
): Promise<
  { txHex: string; irreversible: boolean; blockHeight: number } | undefined
> {
  const cacheKey = `${txHex}:${vout}`;
  const cachedResult = spendingTxCache.get(cacheKey);

  // Check if cached result exists and is irreversible, then return it
  if (cachedResult && cachedResult.irreversible) {
    return cachedResult;
  }

  const tx = Transaction.fromHex(txHex);

  const output = tx.outs[vout];
  if (!output) throw new Error("Invalid out");
  const scriptHash = Buffer.from(crypto.sha256(output.script))
    .reverse()
    .toString("hex");

  //retrieve all txs that sent / received from this scriptHash
  const history = await explorer.fetchTxHistory({ scriptHash });

  for (let i = 0; i < history.length; i++) {
    const txData = history[i];
    if (!txData) throw new Error("Invalid history");
    //Check if this specific tx was spending my output:
    const historyTxHex = await explorer.fetchTx(txData.txId);
    const txHistory = Transaction.fromHex(historyTxHex);
    //For all the inputs in the tx see if one of them was spending from vout and txId
    const found = txHistory.ins.some((input) => {
      const inputPrevtxId = Buffer.from(input.hash).reverse().toString("hex");
      const inputPrevOutput = input.index;
      return inputPrevtxId === tx.getId() && inputPrevOutput === vout;
    });
    if (found)
      return {
        txHex: historyTxHex,
        irreversible: txData.irreversible,
        blockHeight: txData.blockHeight,
      };
  }
  return;
}

function getEsploraAPI(networkId: NetworkId) {
  if (
    !process.env ||
    !process.env.PUBLIC_STORM_SERVER_NAME ||
    !process.env.PUBLIC_STORM_ESPLORA_API_LOCATION ||
    !process.env.LOCAL_PROTOCOL ||
    !process.env.LOCAL_HOST_NAME ||
    !process.env.LOCAL_REGTEST_ESPLORA_API_PORT
  )
    throw new Error("Incomplete env");
  const url =
    networkId === "TESTNET"
      ? "https://blockstream.info/testnet/api/"
      : networkId === "BITCOIN"
        ? "https://blockstream.info/api/"
        : networkId === "STORM"
          ? `${process.env.PUBLIC_PROTOCOL}://${process.env.PUBLIC_STORM_SERVER_NAME}${process.env.PUBLIC_STORM_SERVER_NAME}/${process.env.PUBLIC_STORM_ESPLORA_API_LOCATION}`
          : networkId === "REGTEST"
            ? `${process.env.LOCAL_PROTOCOL}://${process.env.LOCAL_HOST_NAME}:${process.env.LOCAL_REGTEST_ESPLORA_API_PORT}`
            : null;
  if (!url) throw new Error(`Esplora API not available for this network`);
  return url;
}

export type RescueStatus = "SPENT" | "UNVAULT_NOT_TRIGGERED" | SpendingTx;

export async function processRescue(rescue: Rescue): Promise<RescueStatus> {
  const url = getEsploraAPI(rescue.networkId);
  const explorer = new EsploraExplorer({ url });

  // Fetch fee estimates
  const feeEstimates = await explorer.fetchFeeEstimates();
  const nextBlockFeeRate =
    feeEstimates[String(Math.min(...Object.keys(feeEstimates).map(Number)))];

  for (const txId of Object.keys(rescue.rescueTxMap)) {
    let txHex: TxHex;
    try {
      txHex = await explorer.fetchTx(txId);
    } catch (error) {
      if (error instanceof Error && error.message.includes("404")) {
        continue;
      } else {
        console.error(`Error fetching transaction for ID ${txId}:`, error);
        throw error;
      }
    }
    if (txHex) {
      //Now see if this tx has another tx that spent it
      const unlockingTxData = await fetchSpendingTx(txHex, 0, explorer);
      if (unlockingTxData?.irreversible === true) {
        return "SPENT";
      } else {
        const rescueTxs = rescue.rescueTxMap[txId];

        let optimalRescueTx: SpendingTx | null = null;
        let largestFeeRateRescueTx: SpendingTx = rescueTxs[0];
        for (const rescueTx of rescueTxs) {
          if (rescueTx.feeRate > largestFeeRateRescueTx.feeRate)
            largestFeeRateRescueTx = rescueTx;

          // Find a tx with a fee rate just above nextBlockFeeRate for express confirmation
          if (
            rescueTx.feeRate > nextBlockFeeRate &&
            (!optimalRescueTx || rescueTx.feeRate < optimalRescueTx.feeRate)
          )
            optimalRescueTx = rescueTx;
        }
        // Choose the optimal tx for express confirmation, or the one with the largest fee rate
        const selectedRescueTx = optimalRescueTx || largestFeeRateRescueTx;
        return selectedRescueTx;
      }
    }
  }
  return "UNVAULT_NOT_TRIGGERED";
}
