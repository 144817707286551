//IMPORTANT: This file is automatically generated by @thunderdenlab/i18n
//DO NOT EDIT it. Usage: npx i18n-thunderden [src] #to re-generate it.
//More info: @thunderdenlab/i18n README.md

export const locales = [en, es];
export type Locale = "en-US" | "es-ES";
import { use } from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./en";
import es from "./es";

const resources = {
  en: { translation: en },
  es: { translation: es }
};

export async function initI18n(locale: Locale) {
  use(initReactI18next).init({
    resources,
    lng: locale,
    fallbackLng: "en", // Default locale

    interpolation: {
      escapeValue: false,
    },
  });
}
